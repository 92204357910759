import { useState } from "react"
import { connect, useDispatch } from "react-redux"

import { Popover } from "@mui/material"

import classNames from "classnames"
import moment from "moment"
import { Link } from "react-router-dom"
import Values from "values.js"
import "../../../styles/calendar.scss"
import {
  toggleRequestModal,
  claimRecommended,
} from "../../../store/reducers/externalPD"

import theme from "@mobilemind/common/src/theme/theme"

const mapStateToProps = ({ calendar, session }) => {
  return {
    calendar,
    session,
  }
}

function MonthCellView(props) {
  let filteredProps = {
    calendar: props.calendar,
    endDate: props.endDate,
    startDate: props.startDate,
    today: props.today,
    otherMonth: props.otherMonth,
    formatDate: props.formatDate,
    isShaded: props.isShades,
  }

  const { calendar, onDoubleClick } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null)
  }

  let showMore
  let startDate = props.startDate

  let eventsOnDay = calendar.data.filter((event) => {
    let onDay =
      moment(event.startDate).startOf("day").format() ===
      moment(startDate).format()
    if (
      moment(startDate).startOf("day").isBetween(event.startDate, event.endDate)
    ) {
      onDay = true
    }
    return onDay && !event.month
  })

  if (calendar.searchQuery && !calendar.onlyMyEvents) {
    eventsOnDay = eventsOnDay.filter(
      (event) =>
        event.title &&
        event.title.toLowerCase().includes(calendar.searchQuery.toLowerCase())
    )
  } else if (!calendar.searchQuery && calendar.onlyMyEvents) {
    eventsOnDay = eventsOnDay.filter((event) => event.rsvp === "accept")
  } else if (calendar.searchQuery && calendar.onlyMyEvents) {
    eventsOnDay = eventsOnDay.filter(
      (event) =>
        event.rsvp === "accept" &&
        event.title.toLowerCase().includes(calendar.searchQuery.toLowerCase())
    )
  }

  if (eventsOnDay.length > 2) {
    showMore = true
  }

  let numShowMore = eventsOnDay.length - 2

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <td
      style={{ height: 120 }}
      className={classNames(
        "monthCell",
        showMore && "maxEventOverride",
        filteredProps.today && "currentDay"
      )}
    >
      {showMore && (
        <div onClick={handleClick} className="showMoreWrapper">
          <span className="showMore">+{numShowMore} More</span>
        </div>
      )}

      <Popover
        className="showMorePopover"
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ul className="sameDayEvents">
          {eventsOnDay.length > 0 &&
            eventsOnDay.map((event, index) => {
              let isAllDay =
                moment(event.startDate).format("h:mm A") ===
                  moment(event.endDate)
                    .subtract(24, "hours")
                    .format("h:mm A") || event.goal
              let secondary = new Values(theme.palette.secondary.main)
              let backgroundColor = theme.palette.primary.light

              let startDate = event.startDate
              if (moment().isDST() && !moment(startDate).isDST()) {
                startDate = moment(startDate).add(1, "hour")
              } else if (!moment().isDST() && moment(startDate).isDST()) {
                startDate = moment(startDate).subtract(1, "hour")
              }

              if (event.isConference) {
                backgroundColor = theme.palette.primary.dark
              } else if (event.isGoal) {
                backgroundColor = theme.palette.secondary.main
              } else if (event.isExternal) {
                backgroundColor = secondary.shade(35).hexString()
              }

              let path = "/events/event/" + event.drupal_internal__id
              if (event.isConference) {
                path = "/events/conference/" + event.drupal_internal__id
              } else if (event.isGoal) {
                path = "learning-path/" + event.drupal_internal__id
              } else if (event.isExternal) {
                path = "/events"
              } else if (event.isObservation) {
                path = "/events/observation/" + event.drupal_internal__id
              }

              return (
                <li key={event.id + "-" + index}>
                  <Link
                    onClick={() => {
                      if (event.isExternal) {
                        dispatch(toggleRequestModal(true))
                        dispatch(claimRecommended(event.fullEvent))
                      }
                    }}
                    to={path}
                  >
                    <div
                      className={classNames("rsvp")}
                      style={{ backgroundColor }}
                    />
                    {isAllDay ? (
                      <span className="time">All Day</span>
                    ) : (
                      <span className="time">
                        {moment(startDate).format("h:mma")}
                      </span>
                    )}
                    <strong>{event.title}</strong>
                  </Link>
                </li>
              )
            })}
        </ul>
      </Popover>

      <div onClick={onDoubleClick}>
        <div
          className="Cell-text"
          style={{
            zIndex: 5,
            top: !calendar.fetched || !showMore ? 0 : -23,
            backgroundColor: filteredProps.today
              ? theme.palette.secondary.main
              : "white",
            color: filteredProps.today
              ? theme.palette.secondary.contrastText
              : "black",
          }}
        >
          {moment(startDate).format("D")}
        </div>
      </div>
    </td>
  )
}

export default connect(mapStateToProps)(MonthCellView)
