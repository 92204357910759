import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import _ from "lodash"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import { RootState } from "../store"
import { filterPartnerCategories } from "@mobilemind/common/src/functions"

export const getCategories = createAsyncThunk<any, any, { state: RootState }>(
  "categories/getCategories",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()

    let excludedCategories: any[] = session.group.field_categories_to_exclude
      ? session.group.field_categories_to_exclude
      : []

    let pages = 1
    let i = 0
    let categories: any[] = []

    while (i < pages) {
      let query = {
        filter: {
          main: {
            group: {
              conjunction: "OR",
            },
          },
          mobilemind: {
            group: {
              conjunction: "AND",
              memberOf: "main",
            },
          },
          orgNull: {
            condition: {
              path: "field_organization",
              operator: "IS NULL",
              memberOf: "mobilemind",
            },
          },
          partnerNull: {
            condition: {
              path: "field_partner",
              operator: "IS NULL",
              memberOf: "mobilemind",
            },
          },
          org: {
            condition: {
              path: "field_organization.id",
              operator: "=",
              value: session.group.uuid[0].value,
              memberOf: "main",
            },
          },
          partner: {
            condition: {
              path: "field_partner.id",
              operator: "IN",
              value: {},
              memberOf: "main",
            },
          },
        },
        include: "field_category_image",
        page: {
          offset: i * 50,
        },
      }

      session.group.field_partner?.forEach(
        (partner: { target_id: number; target_uuid: string }) => {
          // @ts-ignore
          query.filter.partner.condition.value[partner.target_id] =
            partner.target_uuid
        }
      )

      let response = await fetchWrapper.get(
        "/api/taxonomy_term/category?" + qs.stringify(query)
      )
      if (response.ok) {
        let data = await response.json()
        pages = Math.ceil(Number(data.meta.count) / 50)

        data.data = filterPartnerCategories(data.data, session)

        data.data.forEach((category: any) => {
          let imageData = category.relationships.field_category_image.data

          if (!imageData) {
          } else {
            category.image = data.included.find(
              (included: any) => imageData && imageData.id === included.id
            )
          }

          if (
            !excludedCategories.find(
              (excluded: any) =>
                excluded.target_id === category.attributes.drupal_internal__tid
            )
          ) {
            categories.push(category)
          }
        })
      }
      i++
    }
    return categories
  }
)

type InitialState = {
  fetched: boolean
  data: any[]
  topCategories: any[]
  subCategories: any[]
}

const initialState: InitialState = {
  fetched: false,
  data: [],
  topCategories: [],
  subCategories: [],
}

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action: any) => {
      state.fetched = true
      let categories = action.payload
      state.data = categories

      state.topCategories = categories.filter(
        (category: any) =>
          category.relationships.parent.data[0].id === "virtual"
      )
      state.topCategories = _.orderBy(
        state.topCategories,
        ["attributes.name"],
        ["asc"]
      )

      state.topCategories.forEach((category: any) => {
        category.hasChildren = categories.find(
          (sub: any) => category.id === sub.relationships.parent.data[0].id
        )
        category.isTopLevel = true
      })

      const subCategories = categories.filter(
        (category: any) =>
          category.relationships.parent.data[0].id !== "virtual"
      )

      subCategories.forEach((category: any) => {
        category.isSecondLevel = true

        category.children = subCategories.filter((sub: any) => {
          return sub.relationships.parent.data[0].id === category.id
        })

        category.children.forEach((child: any) => {
          child.isThirdLevel = true
        })

        category.children = _.orderBy(
          category.children,
          ["attributes.name"],
          ["asc"]
        )
      })

      state.subCategories = state.subCategories.concat(
        subCategories.filter(
          (category: any) =>
            category.relationships.parent.data[0].id !== "virtual"
        )
      )
    })
  },
})

export default categoriesSlice.reducer
